import { createStore } from 'vuex'

export default createStore({
  state: {
    currentType: '',
    cname: 'pc'
  },
  getters: {
  },
  mutations: {
    setCurrentType(state, type) {
      state.currentType = type
    },
    setCname(state, url) {
      state.cname = url
    }
  },
  actions: {
  },
  modules: {
  }
})
