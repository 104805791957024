import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus';
import axios from 'axios';
import VueAxios from 'vue-axios';
import isMobile from './isMobile';

let loadingInstance: any = ''

router.beforeEach((to, form, next) => {
  loadingInstance = ElLoading.service({ fullscreen: true });

  // 判断是PC端还是Mobile端
  const urlroute = window.location.pathname.toLowerCase().split('/');
  if (store.state.cname !== urlroute[1]) {
    store.commit('setCname', urlroute[1])
  }

  let path = to.path;
  if (isMobile()) {
    if (path === '/') {
      next({ path: '/mobile' })
    } else if (path.indexOf('/pc') !== -1) {
      path = path.replace('pc', 'mobile');
      next({ path: path })
    } else {
      next();
    }
  } else {
    if (path === '/') {
      next({ path: '/pc' })
    } else if (path.indexOf('/mobile') !== -1) {
      path = path.replace('mobile', 'pc');
      next({ path: path })
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  setTimeout(() => {
    loadingInstance.close();
  }, 1000);
})

router.afterEach(() => {
  setTimeout(() => {
    loadingInstance.close();
  }, 700);
})

const app = createApp(App);


app.use(store).use(router).use(ElementPlus).use(VueAxios, axios).mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}