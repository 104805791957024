import { createRouter, createWebHashHistory, RouteRecordRaw, } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/pc',
    name: 'Home',
    component: () => import('../views/Home/Home.vue')
  },
  {
    path: '/pc/Hardware',
    name: 'Hardware',
    component: () => import('../views/Hardware/Hardware.vue'),
    children: [
      {
        path: '/pc/Hardware/Products',
        name: 'Products',
        component: () => import('../views/Hardware/Products.vue')
      },
      {
        path: '/pc/Hardware/Products/Detail',
        name: 'Detail',
        component: () => import('../views/Hardware/Detail.vue')
      },
      {
        path: '/pc/Hardware/Products/Search',
        name: 'Search',
        component: () => import('../views/Hardware/Search.vue')
      },
      {
        path: '/pc/Hardware/SearchDetail',
        name: 'SearchDetail',
        component: () => import('../views/Hardware/SearchDetail.vue')
      }
    ]

  },
  {
    path: '/pc/Software',
    name: 'Software',
    component: () => import('../views/Software/Software.vue')
  },
  {
    path: '/pc/Engineering',
    redirect: '/pc/Engineering/News',
    name: 'Engineering',
    component: () => import('../views/Engineering/Engineering.vue'),
    children: [
      {
        path: '/pc/Engineering/News',
        name: 'News1',
        component: () => import('../views/Engineering/News.vue')
      },
      {
        path: '/pc/Engineering/News/Detail',
        name: 'NewsDetail1',
        component: () => import('../views/Engineering/Detail.vue')
      },

    ]
  },
  {
    path: '/pc/Settle',
    name: 'Settle',
    component: () => import('../views/Settle/Settle.vue')
  },
  {
    path: '/pc/Firm',
    redirect: '/pc/Firm/News',
    name: 'Firm',
    component: () => import('../views/Firm/Firm.vue'),
    children: [
      {
        path: '/pc/Firm/News',
        name: 'News',
        component: () => import('../views/Firm/News.vue')
      },
      {
        path: '/pc/Firm/News/Detail',
        name: 'NewsDetail',
        component: () => import('../views/Firm/Detail.vue')
      },

    ]
  },
  {
    path: '/pc/About',
    name: 'About',
    component: () => import('../views/About/About.vue')
  },
  {
    path: '/pc/Join',
    name: 'Join',
    component: () => import('../views/Join/Join.vue')
  },
  {
    path: '/pc/productnavigation',
    name: 'productnavigation',
    component: () => import('../views/productnavigation.vue')
  },


  {
    path: '/mobile',
    name: 'mobileHome',
    component: () => import('../views/Home/mobileHome.vue')
  },
  {
    path: '/mobile/Hardware',
    name: 'mobileHardware',
    component: () => import('../views/Hardware/mobileHardware.vue'),
    children: [
      {
        path: '/mobile/Hardware/Products',
        name: 'mobileProducts',
        component: () => import('../views/Hardware/mobileProducts.vue')
      },
      {
        path: '/mobile/Hardware/Products/Detail',
        name: 'mobileDetail',
        component: () => import('../views/Hardware/mobileDetail.vue')
      },
      {
        path: '/mobile/Hardware/Products/Search',
        name: 'mobileSearch',
        component: () => import('../views/Hardware/mobileSearch.vue')
      },
      {
        path: '/mobile/Hardware/SearchDetail',
        name: 'mobileSearchDetail',
        component: () => import('../views/Hardware/mobileSearchDetail.vue')
      }
    ]

  },
  {
    path: '/mobile/Software',
    name: 'mobileSoftware',
    component: () => import('../views/Software/mobileSoftware.vue')
  },
  {
    path: '/mobile/Engineering',
    redirect: '/mobile/Engineering/News',
    name: 'mobileEngineering',
    component: () => import('../views/Engineering/mobileEngineering.vue'),
    children: [
      {
        path: '/mobile/Engineering/News',
        name: 'mobileNews1',
        component: () => import('../views/Engineering/mobileNews.vue')
      },
      {
        path: '/mobile/Engineering/News/Detail',
        name: 'mobileNewsDetail1',
        component: () => import('../views/Engineering/mobileDetail.vue')
      },

    ]
  },
  {
    path: '/mobile/Settle',
    name: 'mobileSettle',
    component: () => import('../views/Settle/mobileSettle.vue')
  },
  {
    path: '/mobile/Firm',
    redirect: '/mobile/Firm/News',
    name: 'mobileFirm',
    component: () => import('../views/Firm/mobileFirm.vue'),
    children: [
      {
        path: '/mobile/Firm/News',
        name: 'mobileNews',
        component: () => import('../views/Firm/mobileNews.vue')
      },
      {
        path: '/mobile/Firm/News/Detail',
        name: 'mobileNewsDetail',
        component: () => import('../views/Firm/mobileDetail.vue')
      },

    ]
  },
  {
    path: '/mobile/About',
    name: 'mobileAbout',
    component: () => import('../views/About/mobileAbout.vue')
  },
  {
    path: '/mobile/Join',
    name: 'mobileJoin',
    component: () => import('../views/Join/mobileJoin.vue')
  },
  {
    path: '/mobile/productnavigation',
    name: 'mobileproductnavigation',
    component: () => import('../views/mobileproductnavigation.vue')
  },


  {
    path: '/downloadUserApp',
    name: 'downloadUserApp',
    component: () => import('../views/downloadUserApp.vue')
  },
  {
    path: '/downloadUserApp1',
    name: 'downloadUserApp1',
    component: () => import('../views/downloadUserApp1.vue')
  },
  {
    path: '/downloadUserApp2',
    name: 'downloadUserApp2',
    component: () => import('../views/downloadUserApp2.vue')
  },
  {
    path: '/downloadUserApp3',
    name: 'downloadUserApp3',
    component: () => import('../views/downloadUserApp3.vue')
  },
  {
    path: '/downloadUserApp4',
    name: 'downloadUserApp4',
    component: () => import('../views/downloadUserApp4.vue')
  },
  {
    path: '/downloadUserApp5',
    name: 'downloadUserApp5',
    component: () => import('../views/downloadUserApp5.vue')
  },
  {
    path: '/downloadUserApp6',
    name: 'downloadUserApp6',
    component: () => import('../views/downloadUserApp6.vue')
  },
]


document.title = '上海畅居智慧官网';


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,


})

export default router
